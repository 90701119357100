import { Box, ChipProps, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Button, Card, Chip, designTokens, Grid, Modal, ModalActions, ModalTitle, Table, Typography } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import CronBuilder from '../../../../CronBuilder/CronBuilder';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { useToast } from '../../../../ToastProvider';
import { NextRunModal } from './NextRunModal';
import { UPDATE_SOLUTION_INSTANCE_SCHEDULE } from './reducer/action_types';
import { TaskModal } from './TaskModal';

interface ExecutionProps {
  tableName: string; // used for column customization as a key in local storage
  taskRerunEnabled: boolean;
  workflowTemplateName?: string;
  onViewSummaryClickHandler?: (args: { row: any }, utils?: { [key: string]: any }) => void;
  onDownloadClickHandler?: (args: { row: any }) => void;
}

// Solution Instance is a legacy term from Netsuite connector, but it just means a workflow instance
export type SolutionInstance = {
  id: string;
  solution_instance_name: string;
  created_at: string;
  active_version_id: string;
  scheduled_trigger: string;
  interval: string;
  timezone: string;
};

export const WorkflowExecution: FC<ExecutionProps> = ({
  tableName,
  taskRerunEnabled,
  workflowTemplateName,
  onViewSummaryClickHandler,
  onDownloadClickHandler
}: ExecutionProps) => {
  const [cron, setCron] = useState({
    timezone: 'UTC',
    expression: ['*', '*', '*', '*', '*', '*']
  });
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [submitting, setSubmitting] = useState(false);
  const { setToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    currentPage: 0
  });
  const [singlePageLoadingOptions, setSinglePageLoadingOptions] = useState({
    isPreviousPageDisabled: true,
    isNextPageDisabled: true,
    currentPage: 0,
  });
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [workflowRunId, setWorkflowRunId] = useState(null);
  const [workflowRunNumber, setWorkflowRunNumber] = useState(null);
  const workflowName = state.workflow_template_name || workflowTemplateName;
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const activeTabName = searchParams.get('tabname');
  const autoRefreshIntervalId = `${workflowName}IntervalId`;
  const EXECUTION_DETAILS_COLUMNS = useMemo(
    () => [
      {
        field: 'id',
        label: 'Id',
        sortable: false
      },
      {
        field: 'workflow_run_number',
        label: 'Execution Run Number',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          return (
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setWorkflowRunId(row.id);
                setWorkflowRunNumber(row.workflow_run_number);
                setOpenTasksModal(true);
              }}
            >
              {value}
            </a>
          );
        }
      },
      {
        field: 'status',
        label: 'Status',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          let state: ChipProps['color'] = 'success';
    
          switch (value) {
            case 'SUCCEEDED':
            case 'Finished':
              state = 'success';
              break;
            case 'FAILED':
            case 'Stopped':
            case 'Stopping':
              state = 'error';
              break;
            case 'RUNNING':
            case 'Processing':
              state = 'primary';
              break;
            case 'WARNING':
              state = 'warning';
              break;
            case 'Queued':
            case 'Pending':
              state = 'default';
              break;
            default:
              state = 'default';
          }

          if (value === 'Finished') {
            value = row.pending_tasks ? 'Pending' : row.failed_tasks ? 'Error' : value;
            state = row.pending_tasks ? 'default' : row.failed_tasks ? 'error' : state;
          }
    
          return <Chip label={<Typography variant='overline'>{value}</Typography>} size='medium' state={state} />
        }
      },
      // TODO(Duc):
      //  - Disable sorting on Start Date for now as it only sorts the current page which is not really useful
      //  - Only enable sorting when we could implement a true sorting mechanism
      {
        field: 'created_at',
        label: 'Start Date',
        sortable: false
      },
      {
        field: 'finished_at',
        label: 'End Date',
        sortable: false
      },
      {
        field: 'run_time',
        label: 'Duration',
        sortable: false,
        dsRenderCell: ({ row }) => {
          const duration = Date.parse(row.finished_at) - Date.parse(row.created_at);
          return Number.isNaN(duration) ? 'N/A' : new Date(duration).toISOString().substring(11, 19);
        }
      },
      {
        field: 'total_tasks',
        label: 'Total',
        labelTooltip: 'Total Tasks',
        sortable: false
      },
      {
        field: 'queued_tasks',
        label: 'Queued',
        labelTooltip: 'Queued Tasks',
        sortable: false
      },
      {
        field: 'processing_tasks',
        label: 'Processing',
        labelTooltip: 'Processing Tasks',
        sortable: false
      },
      {
        field: 'pending_tasks',
        label: 'Pending',
        labelTooltip: 'Pending Tasks',
        sortable: false
      },
      {
        field: 'stopped_tasks',
        label: 'Stopped',
        labelTooltip: 'Stopped Tasks',
        sortable: false
      },
      {
        field: 'successful_tasks',
        label: 'Successful',
        labelTooltip: 'Successful Tasks',
        sortable: false
      },
      {
        field: 'failed_tasks',
        label: 'Failed',
        labelTooltip: 'Failed Tasks',
        sortable: false
      }
    ], 
    []
  );
  const [columns, setColumns] = useState(() => {
    const savedColumns = JSON.parse(localStorage.getItem(`${tableName}_columns`));
    if (savedColumns) {
      const merged = savedColumns.map(savedColumn => {
        const matchedColumn = EXECUTION_DETAILS_COLUMNS.find(col => col.field === savedColumn.field);
        if (matchedColumn?.dsRenderCell) {
          savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
        }
        savedColumn.sortable = !!matchedColumn?.sortable;
        return savedColumn;
      });
      return merged;
    } else {
      return EXECUTION_DETAILS_COLUMNS;
    }
  });
  const [searchValue, setSearchValue] = useState(null);
  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [openNextRunModal, setOpenNextRunModal] = useState(false);
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const getExecutions = async ({ page, rowsPerPage }, showLoadingIcon = true) => {
    showLoadingIcon && setLoading(true);
    setPageDetails({ currentPage: page, pageSize: rowsPerPage });

    try {
      const extraQueryParams = `page=${page + 1}&page_length=${rowsPerPage}&order_by=created_at&order_dir=desc`;
  
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs?tags[]=${workflowName}&${extraQueryParams}`,
        'GET',
        undefined,
        { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data: executions, pagination } = await response.json();
      const rows = executions.map(execution => ({
        id: execution.id,
        solution_instance_name: execution.definitionName,
        workflow_run_number: execution.name,
        status: execution.status,
        run_time: execution.runTime || 'N/A',
        created_at: execution.createdAt || 'N/A',
        finished_at: execution.finishedAt || 'N/A',
        total_tasks: execution.tasks.total,
        pending_tasks: execution.tasks.pending,
        stopped_tasks: execution.tasks.stopped,
        failed_tasks: execution.tasks.error,
        successful_tasks: execution.tasks.success,
        queued_tasks: execution.tasks.queued,
        processing_tasks: execution.tasks.processing
      }));

      showLoadingIcon && setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: page,
        isPreviousPageDisabled: page === 0,
        isNextPageDisabled: !pagination.next_page
      });
      setRows(rows);
    } catch (err) {
      Connect.log(err);
      showLoadingIcon && setLoading(false);
    }
  };

  const execute = async () => {
    if (!submitting) {
      setSubmitting(true);

      const instance = state.settingsHash['solutionInstances'].find(
        (instance: SolutionInstance) => instance.solution_instance_name === state.settingsHash['execution']['solution_instance_name']
      );
      
      try {
        let response;
        const isScheduled = state.settingsHash['execution']?.['execution_mode'] === 'Schedule';

        if (isScheduled && cron.timezone === '') {
          throw new Error('Timezone cannot be empty. Please select a timezone and try again.');
        }

        const headers = { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal', 'Content-Type': 'application/json' };

        // update workflow with schedule
        if (isScheduled) {
          await Connect.proxyCall(
            `${workflowProxyPath}/workflows/versions/${instance.active_version_id}`,
            'PUT',
            {
              workflow: {
                scheduled_trigger: isScheduled,
                interval: isScheduled ? cron.expression.join(' ').trim() : '',
                timezone: isScheduled ? cron.timezone : ''
              }
            },
            headers
          );
        } else {
          // run workflow when it's on demand
          response = await Connect.proxyCall(
            `${workflowProxyPath}/workflows/${instance.id}/run`,
            'POST',
            {},
            headers
          );
        }

        if (response && !response.ok) throw Error(response.statusText);

        // update solution instance state if it's scheduled
        if (isScheduled) {
          dispatch({
            type: UPDATE_SOLUTION_INSTANCE_SCHEDULE,
            payload: {
              id: instance.id,
              scheduled_trigger: isScheduled,
              interval: isScheduled ? cron.expression.join(' ').trim() : '',
              timezone: isScheduled ? cron.timezone : ''
            }
          });
        } else {
          await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });
        }

        setSubmitting(false);
        setToast({
          message: isScheduled ? 'Successfully scheduled a run' : 'Successfully submitted a run',
          severity: 'success',
          keyRender: Date.now()
        });
      } catch (err) {
        Connect.log(err);
        setSubmitting(false);
        setToast({
          message: err.message || 'Failed to submit',
          severity: 'error',
          keyRender: Date.now()
        });
      } finally {
        setOpenExecutionModal(false);
        dispatch({type: 'execution', payload: { 'execution_mode': 'Run Now' }});
      }
    }
  };

  const onViewTasksClickHandler = (args: { row: any }) => {
    const { row } = args;
    setWorkflowRunId(row.id);
    setWorkflowRunNumber(row.workflow_run_number);
    setOpenTasksModal(true);
  };

  const onStopClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to stop ${row.workflow_run_number}`)) {
      return;
    }

    setLoading(true);

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}/stop`,
        'PUT',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal',
          'Accept': 'application/json'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { success } = await response.json();
      
      if (!success) throw Error('Failed to stop');

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        message: `Successfully stopped ${row.workflow_run_number}`,
        severity: 'success',
        keyRender: Date.now()
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        message: `Failed to stop ${row.workflow_run_number}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  const onDeleteClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to delete ${row.workflow_run_number}`)) {
      return;
    }
    
    setLoading(true);

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}`,
        'DELETE',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        message: `Successfully deleted ${row.workflow_run_number}`,
        severity: 'success',
        keyRender: Date.now()
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        message: `Failed to delete ${row.workflow_run_number}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  const getExecution = async (workflowRunId: string) => {
    setLoading(true);

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs/${workflowRunId}`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const execution = await response.json();

      if (execution.errors) {
        setRows([]);
      } else {
        setRows([{
          id: execution.id,
          solution_instance_name: execution.definitionName,
          workflow_run_number: execution.name,
          status: execution.status,
          run_time: execution.runTime || 'N/A',
          created_at: execution.createdAt || 'N/A',
          finished_at: execution.finishedAt || 'N/A',
          total_tasks: execution.tasks.total,
          pending_tasks: execution.tasks.pending,
          stopped_tasks: execution.tasks.stopped,
          failed_tasks: execution.tasks.error,
          successful_tasks: execution.tasks.success,
          queued_tasks: execution.tasks.queued,
          processing_tasks: execution.tasks.processing
        }]);
      }

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: 0,
        isPreviousPageDisabled: true,
        isNextPageDisabled: true
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        message: 'Failed to search an execution run',
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  const JobSummaryModal = (
    <Modal
      id='show-job-summary-modal'
      open={openSummaryModal}
      disableBackdropClick={true}
      dsOnClose={() => setOpenSummaryModal(false)}
      header={<ModalTitle dsOnClose={() => setOpenSummaryModal(false)}>Sync Job Summary</ModalTitle>}
      body={<div><pre>{JSON.stringify(summaryModalData, null, 2) }</pre></div>}
    />
  );

  const submitButton = (
    <Box sx={{m: 1, position: 'relative'}}>
      {
        <Button
          disabled={
            submitting || 
            state.settingsHash['execution']?.['execution_mode'] == null ||
            state.settingsHash['execution']?.['solution_instance_name'] == null ||
            (state.settingsHash['execution']?.['execution_mode'] === 'Schedule' && cron.timezone === '')
          }
          tooltip={state.settingsHash['execution']?.['execution_mode'] === 'Schedule' && cron.timezone === '' ? 'Timezone cannot be empty' : null}
          endIcon={state.settingsHash['execution']?.['execution_mode'] === 'Schedule' ? 'save' : 'play_circle_outline'}
          dsOnClick={() => execute()}
          body={state.settingsHash['execution']?.['execution_mode'] === 'Schedule' ? 'Save' : 'Run'}
        />
      }
      {
        submitting &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '40px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );

  const executionModalBody = (
    <Grid item xs={12}>
      <Card
        id='execution-top-ui'
        variant='outlined'
        body={
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label={<b>Execution Mode</b>}
                value={state.settingsHash['execution']?.['execution_mode'] || 'Run Now'}
              >
                {
                  ['Run Now', 'Schedule'].map((option: string, idx: number) => (
                    <MenuItem
                      key={idx}
                      value={option}
                      onClick={() => {
                        dispatch({type: 'execution', payload: { 'execution_mode': option }});
                        const instance = state.settingsHash['solutionInstances']?.find((s: SolutionInstance) => s.solution_instance_name === state.settingsHash['execution']?.['solution_instance_name']);
                        if (instance) {
                          setCron({
                            ...cron,
                            timezone: instance.timezone || 'UTC',
                            expression: instance.interval ? instance.interval.split(' ') : ['*', '*', '*', '*', '*', '*']
                          });
                        }
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            {
              state.settingsHash['execution']?.['execution_mode'] === 'Schedule' &&
              <Grid item xs={12}>
                <CronBuilder
                  cron={cron}
                  setCron={setCron}
                  showInterval={false}
                />
              </Grid>
            }
          </Grid>
        }
      />
    </Grid>
  );

  const ExecutionModal = (
    <Modal
      id='execution-modal'
      open={openExecutionModal}
      dsOnClose={() => {
        setOpenExecutionModal(false);
        dispatch({type: 'execution', payload: { 'execution_mode': '' }});
      }}
      body={executionModalBody}
      header={
        <ModalTitle dsOnClose={() => setOpenExecutionModal(false)}>Execution/Schedule Configuration</ModalTitle>
      }
      footer={
        <ModalActions>{submitButton}</ModalActions>
      }
      disableBackdropClick
      fullWidth
    />
  );

  const ExecutionDetails = (
    <Grid item xs={12}>
      <Card
        id='execution-bottom-ui'
        titleBar
        header='Execution Details'
        headerAction={
          <>
            <Button icon='play_circle_outline' tooltip='Run' dsOnClick={() => setOpenExecutionModal(true)} />
            <Button icon='schedule' tooltip='Next Runs' dsOnClick={() => setOpenNextRunModal(true)} />
          </>
        }
        body={
          <Table
            loading={loading}
            columns={columns}
            rows={rows}
            uniqueKey='execution-table'
            tableActions={[
              {
                icon: 'refresh',
                tooltip: 'Reload Table',
                onClick: () => getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize })
              }
            ]}
            rowActions={
              [
                {
                  dsGetActionData: () => ({
                    icon: 'visibility',
                    tooltip: 'Tasks',
                    onClick: onViewTasksClickHandler
                  })
                },
                onViewSummaryClickHandler && {
                  dsGetActionData: () => ({
                    icon: 'description',
                    tooltip: 'Summary',
                    onClick: (args: { row: any }) => {
                      setLoading(true);
                      onViewSummaryClickHandler(args, { setSummaryModalData, setOpenSummaryModal });
                      setLoading(false);
                    }
                  })
                },
                onDownloadClickHandler && {
                  dsGetActionData: (row) => ({
                    icon: 'cloud_download',
                    tooltip: 'Download',
                    disabled: row.status !== 'Finished',
                    onClick: (args: { row: any }) => {
                      setLoading(true);
                      onDownloadClickHandler(args);
                      setLoading(false);
                    }
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'stop_circle',
                    tooltip: 'Stop',
                    disabled: ['Finished', 'Stopped', 'Stopping'].includes(row.status as string),
                    onClick: onStopClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'delete_outline',
                    tooltip: 'Delete',
                    disabled: ['Queued', 'Processing', 'Pending', 'Stopping'].includes(row.status as string),
                    onClick: onDeleteClickHandler
                  })
                }
              ].filter(action => !!action) as any // filter out valid actions when onViewSummaryClickHandler or onDownloadClickHandler is not defined
            }
            rowDisplayOptions={{
              hoverEffect: false
            }}
            rowsPerPage={pageDetails.pageSize}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
            dsOnPage={getExecutions}
            hideTotalRows
            singlePageLoadingOptions={singlePageLoadingOptions}
            orderable
            {...{searchable: true}}
            dsOnColumnsChange={(columns) => {
              localStorage.setItem(`${tableName}_columns`, JSON.stringify(columns));
            }}
            dsOnSearchChange={(args) => setSearchValue(args)}
            showNoRowsMessage={rows?.length === 0}
            searchPlaceholder='Type execution run number/id to filter results...'
          />
        }
      />
    </Grid>
  );

  useEffect(() => {
    if (searchValue === null) {
      return;
    }

    if (searchValue.length === 0) {
      getExecutions({ page: 0, rowsPerPage: 10 });
      return;
    }

    const timer = setTimeout(() => getExecution(searchValue), 1000);
    
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    // Add a little bit of delay when loading executions to avoid race condition in the backend
    // when trying to load connector
    let timer = null;
    if (state.settingsHash['authentication']) {
      timer = setTimeout(() => getExecutions({ page: 0, rowsPerPage: 10 }), 1500);
    }

    // Default execution mode to Run Now
    dispatch({type: 'execution', payload: { 'execution_mode': 'Run Now' }});

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const defaultInstance = state.settingsHash['solutionInstances']?.find((s: SolutionInstance) => s.solution_instance_name === workflowName);
    if (defaultInstance) {
      dispatch({
        type: 'execution',
        payload: {
          'solution_instance_name': defaultInstance.solution_instance_name || ''
        }
      });
    }
  }, [state.settingsHash['solutionInstances']]);

  useEffect(() => {
    // Disable auto refresh when execution tab is not active
    if (activeTabName === 'execution') {
      // Add auto refresh
      window[autoRefreshIntervalId] = setInterval(() => {
        setPageDetails((prevPageDetails) => {
          getExecutions({ page: prevPageDetails.currentPage, rowsPerPage: prevPageDetails.pageSize }, false);
          return prevPageDetails;
        });
      }, (state.workflow_auto_refresh_frequency || 10) * 1000);
    } else {
      window[autoRefreshIntervalId] = clearInterval(window[autoRefreshIntervalId]);
    }
  }, [activeTabName]);
  
  return (
    <Grid container spacing={2}>
      { JobSummaryModal }
      <TaskModal tableName={tableName} taskRerunEnabled={taskRerunEnabled} workflowRunId={workflowRunId} workflowRunNumber={workflowRunNumber} open={openTasksModal} setOpenTasksModal={setOpenTasksModal} />
      <NextRunModal open={openNextRunModal} setOpenNextRunModal={setOpenNextRunModal} workflowTemplateName={workflowTemplateName} />
      { ExecutionModal }
      { ExecutionDetails }
    </Grid>
  );
};
