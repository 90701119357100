import { Box, ChipProps, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Alert, Button, Card, Chip, Grid, Modal, ModalTitle, Table, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { TasksModal } from './TasksModal';
import { WDB_UPDATE_SOLUTION_INSTANCE_SCHEDULE } from '../action_types';
import CronBuilder from '../../../../CronBuilder/CronBuilder';
import { ExecutionModal } from './ExecutionModal';
import { NextRunModal } from './NextRunModal';
import { useIdentity } from '../../../../IdentityProvider';
import { useFlipperHook } from '../../../../Tables/helpers';

interface ExecutionProps {
  tabName: string;
  newLayout: boolean;
}

const EXECUTION_DETAILS_TABLE_NAME = 'wdb_execution_details';

export const Execution: FC<ExecutionProps> = ({
  tabName,
  newLayout
}: ExecutionProps) => {
  const [cron, setCron] = useState({
    timezone: 'UTC',
    expression: ['*', '*', '*', '*', '*', '*']
  });
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    currentPage: 0
  });
  const [singlePageLoadingOptions, setSinglePageLoadingOptions] = useState({
    isPreviousPageDisabled: true,
    isNextPageDisabled: true,
    currentPage: 0,
  });
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [workflowRunId, setWorkflowRunId] = useState(null);
  const [workflowRunNumber, setWorkflowRunNumber] = useState(null);
  const workflowName = state.workflow_template_name || 'z-IntegrationHub-WorkdayBilling';
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const activeTabName = searchParams.get('tabname');
  const EXECUTION_DETAILS_COLUMNS = useMemo(
    () => [
      {
        field: 'id',
        label: 'Id',
        sortable: false
      },
      {
        field: 'workflow_run_number',
        label: 'Execution Run Number',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          return (
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setWorkflowRunId(row.id);
                setWorkflowRunNumber(row.workflow_run_number);
                setOpenTasksModal(true);
              }}
            >
              {value}
            </a>
          );
        }
      },
      {
        field: 'status',
        label: 'Status',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          let state: ChipProps['color'] = 'success';
    
          switch (value) {
            case 'SUCCEEDED':
            case 'Finished':
              state = 'success';
              break;
            case 'FAILED':
            case 'Stopped':
            case 'Stopping':
              state = 'error';
              break;
            case 'RUNNING':
            case 'Processing':
              state = 'primary';
              break;
            case 'WARNING':
              state = 'warning';
              break;
            case 'Queued':
            case 'Pending':
              state = 'default';
              break;
            default:
              state = 'default';
          }

          if (value === 'Finished') {
            value = row.pending_tasks ? 'Pending' : row.failed_tasks ? 'Error' : value;
            state = row.pending_tasks ? 'default' : row.failed_tasks ? 'error' : state;
          }
    
          return <Chip label={<Typography variant='overline'>{value}</Typography>} size='medium' state={state} />
        }
      },
      {
        field: 'created_at',
        label: 'Start Date',
        sortable: true
      },
      {
        field: 'finished_at',
        label: 'End Date',
        sortable: false
      },
      {
        field: 'run_time',
        label: 'Duration',
        sortable: false,
        dsRenderCell: ({ row }) => {
          const duration = Date.parse(row.finished_at) - Date.parse(row.created_at);
          return Number.isNaN(duration) ? 'N/A' : new Date(duration).toISOString().substring(11, 19);
        }
      },
      {
        field: 'total_tasks',
        label: 'Total',
        labelTooltip: 'Total Tasks',
        sortable: false
      },
      {
        field: 'queued_tasks',
        label: 'Queued',
        labelTooltip: 'Queued Tasks',
        sortable: false
      },
      {
        field: 'processing_tasks',
        label: 'Processing',
        labelTooltip: 'Processing Tasks',
        sortable: false
      },
      {
        field: 'pending_tasks',
        label: 'Pending',
        labelTooltip: 'Pending Tasks',
        sortable: false
      },
      {
        field: 'stopped_tasks',
        label: 'Stopped',
        labelTooltip: 'Stopped Tasks',
        sortable: false
      },
      {
        field: 'successful_tasks',
        label: 'Successful',
        labelTooltip: 'Successful Tasks',
        sortable: false
      },
      {
        field: 'failed_tasks',
        label: 'Failed',
        labelTooltip: 'Failed Tasks',
        sortable: false
      }
    ], 
    []
  );
  const [columns, setColumns] = useState(() => {
    const savedColumns = JSON.parse(localStorage.getItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`));
    if (savedColumns) {
      const merged = savedColumns.map(savedColumn => {
        const matchedColumn = EXECUTION_DETAILS_COLUMNS.find(col => col.field === savedColumn.field);
        if (matchedColumn?.dsRenderCell) {
          savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
        }
        savedColumn.sortable = !!matchedColumn?.sortable;
        return savedColumn;
      });
      return merged;
    } else {
      return EXECUTION_DETAILS_COLUMNS;
    }
  });
  const [searchValue, setSearchValue] = useState(null);
  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [openNextRunModal, setOpenNextRunModal] = useState(false);
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const { hasPermission } = useIdentity();
  const isConnectorEnabled = hasPermission('permission.ZuoraWorkdayConnector') || state.enableWorkdayConnector;
  const autoRefreshIntervalId = `${workflowName}IntervalId`;

  const getExecutions = async ({ page, rowsPerPage }, showLoadingIcon = true) => {
    showLoadingIcon && setLoading(true);
    setPageDetails({ currentPage: page, pageSize: rowsPerPage });

    try {
      const extraQueryParams = `page=${page + 1}&page_length=${rowsPerPage}&order_by=created_at&order_dir=desc`;
  
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs?tags[]=${workflowName}&${extraQueryParams}`,
        'GET',
        undefined,
        { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data: executions, pagination } = await response.json();
      const rows = executions.map(execution => ({
        id: execution.id,
        solution_instance_name: execution.definitionName,
        workflow_run_number: execution.name,
        status: execution.status,
        run_time: execution.runTime || 'N/A',
        created_at: execution.createdAt || 'N/A',
        finished_at: execution.finishedAt || 'N/A',
        total_tasks: execution.tasks.total,
        pending_tasks: execution.tasks.pending,
        stopped_tasks: execution.tasks.stopped,
        failed_tasks: execution.tasks.error,
        successful_tasks: execution.tasks.success,
        queued_tasks: execution.tasks.queued,
        processing_tasks: execution.tasks.processing
      }));

      showLoadingIcon && setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: page,
        isPreviousPageDisabled: page === 0,
        isNextPageDisabled: !pagination.next_page
      });
      setRows(rows);
    } catch (err) {
      Connect.log(err);
      showLoadingIcon && setLoading(false);
    }
  };

  const execute = async () => {
    if (!submitting) {
      setSubmitting(true);
      setToast({...toast, show: false});

      const instance = state.settingsHash['solutionInstances'].find(
        instance => instance.solution_instance_name === state.settingsHash[tabName]['solution_instance_name']
      );
      
      try {
        let response;
        const isScheduled = state.settingsHash[tabName]?.['execution_mode'] === 'Schedule';

        if (isScheduled && cron.timezone === '') {
          throw new Error('Timezone cannot be empty. Please select a timezone and try again.');
        }

        const headers = { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal', 'Content-Type': 'application/json' };

        // update workflow with schedule
        if (isScheduled) {
          await Connect.proxyCall(
            `${workflowProxyPath}/workflows/versions/${instance.active_version_id}`,
            'PUT',
            {
              workflow: {
                scheduled_trigger: isScheduled,
                interval: isScheduled ? cron.expression.join(' ').trim() : '',
                timezone: isScheduled ? cron.timezone : ''
              }
            },
            headers
          );
        } else {
          // run workflow when it's on demand
          response = await Connect.proxyCall(
            `${workflowProxyPath}/workflows/${instance.id}/run`,
            'POST',
            {},
            headers
          );
        }

        if (response && !response.ok) throw Error(response.statusText);

        // update solution instance state if it's scheduled
        if (isScheduled) {
          dispatch({
            type: WDB_UPDATE_SOLUTION_INSTANCE_SCHEDULE,
            payload: {
              id: instance.id,
              scheduled_trigger: isScheduled,
              interval: isScheduled ? cron.expression.join(' ').trim() : '',
              timezone: isScheduled ? cron.timezone : ''
            }
          });
        } else {
          await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });
        }

        setSubmitting(false);
        setToast({
          show: true,
          msg: isScheduled ? 'Successfully scheduled a run' : 'Successfully submitted a run',
          severity: 'success'
        });
      } catch (err) {
        Connect.log(err);
        setSubmitting(false);
        setToast({
          show: true,
          msg: err.message || 'Failed to submit',
          severity: 'error'
        });
      } finally {
        setOpenExecutionModal(false);
        dispatch({type: tabName, payload: { 'execution_mode': 'Run Now' }});
      }
    }
  };

  const onViewTasksClickHandler = (args: { row: any }) => {
    const { row } = args;
    setWorkflowRunId(row.id);
    setWorkflowRunNumber(row.workflow_run_number);
    setOpenTasksModal(true);
  };

  const onViewSummaryClickHandler = async (args: { row: any }) => {
    const { row } = args;
    setLoading(true);
    setToast({...toast, show: false});

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=Get Sync Job Summary`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No summary was found. Please try again later.');

      const syncJobResult = data[0]?.['data']?.['syncJobResult'];
      let summary = null;

      if (Array.isArray(syncJobResult)) {
        if (syncJobResult.length === 0) throw Error('No summary was found. Please try again later.');
        summary = syncJobResult[syncJobResult.length - 1]?.result;
      } else {
        summary = syncJobResult?.result;
      }

      if (!summary) throw Error('No summary was found. Please try again later.');

      setSummaryModalData(summary);
      setOpenSummaryModal(true);
      setLoading(false);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to retrieve summary: ${err.message}`,
        severity: 'error'
      });
    }
  };

  const onStopClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to stop ${row.workflow_run_number}`)) {
      return;
    }

    setLoading(true);
    setToast({...toast, show: false});

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}/stop`,
        'PUT',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal',
          'Accept': 'application/json'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { success } = await response.json();
      
      if (!success) throw Error('Failed to stop');

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully stopped ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to stop ${row.workflow_run_number}`,
        severity: 'error'
      });
    }
  };

  const onDeleteClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to delete ${row.workflow_run_number}`)) {
      return;
    }
    
    setLoading(true);
    setToast({...toast, show: false});

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/${row.id}`,
        'DELETE',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully deleted ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to delete ${row.workflow_run_number}`,
        severity: 'error'
      });
    }
  };

  const getExecution = async (workflowRunId: string) => {
    setLoading(true);
    setToast({...toast, show: false});

    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/workflow_runs/${workflowRunId}`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const execution = await response.json();

      if (execution.errors) {
        setRows([]);
      } else {
        setRows([{
          id: execution.id,
          solution_instance_name: execution.definitionName,
          workflow_run_number: execution.name,
          status: execution.status,
          run_time: execution.runTime || 'N/A',
          created_at: execution.createdAt || 'N/A',
          finished_at: execution.finishedAt || 'N/A',
          total_tasks: execution.tasks.total,
          pending_tasks: execution.tasks.pending,
          stopped_tasks: execution.tasks.stopped,
          failed_tasks: execution.tasks.error,
          successful_tasks: execution.tasks.success,
          queued_tasks: execution.tasks.queued,
          processing_tasks: execution.tasks.processing
        }]);
      }

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: 0,
        isPreviousPageDisabled: true,
        isNextPageDisabled: true
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to search an execution run',
        severity: 'error'
      });
    }
  };

  const submitButton = (
    <Box sx={{m: 1, position: 'relative'}}>
      {
        <Button
          disabled={
            submitting || 
            state.settingsHash[tabName]?.['execution_mode'] == null ||
            state.settingsHash[tabName]?.['solution_instance_name'] == null ||
            (state.settingsHash[tabName]?.['execution_mode'] === 'Schedule' && cron.timezone === '')
          }
          tooltip={state.settingsHash[tabName]?.['execution_mode'] === 'Schedule' && cron.timezone === '' ? 'Timezone cannot be empty' : null}
          endIcon={state.settingsHash[tabName]?.['execution_mode'] === 'Schedule' ? 'save' : 'play_circle_outline'}
          dsOnClick={() => execute()}
          body={state.settingsHash[tabName]?.['execution_mode'] === 'Schedule' ? 'Save' : 'Run'}
        />
      }
      {
        submitting &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '40px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );

  useEffect(() => {
    if (isConnectorEnabled) {
      if (searchValue === null) {
        return;
      }
  
      if (searchValue.length === 0) {
        getExecutions({ page: 0, rowsPerPage: 10 });
        return;
      }
  
      const timer = setTimeout(() => getExecution(searchValue), 1000);
      
      return () => clearTimeout(timer);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isConnectorEnabled) {
      // Add a little bit of delay when loading executions to avoid race condition in the backend
      // when trying to load Workday connector
      let timer = null;
      if (state.settingsHash['authentication']) {
        timer = setTimeout(() => getExecutions({ page: 0, rowsPerPage: 10 }), 1500);
      }

      // Default execution mode to Run Now
      dispatch({type: tabName, payload: { 'execution_mode': 'Run Now' }});

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isConnectorEnabled]);

  useEffect(() => {
    if (isConnectorEnabled) {
      const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === workflowName);
      if (defaultInstance) {
        dispatch({
          type: tabName,
          payload: {
            'solution_instance_name': defaultInstance.solution_instance_name || ''
          }
        });
      }
    }
  }, [state.settingsHash['solutionInstances']]);

  useEffect(() => {
    if (isConnectorEnabled) {
      // Disable auto refresh when execution tab is not active
      if (activeTabName === tabName) {
        // Add auto refresh
        window[autoRefreshIntervalId] = setInterval(() => {
          setPageDetails((prevPageDetails) => {
            getExecutions({ page: prevPageDetails.currentPage, rowsPerPage: prevPageDetails.pageSize }, false);
            return prevPageDetails;
          });
        }, (state.workflow_auto_refresh_frequency || 10) * 1000);
      } else {
        window[autoRefreshIntervalId] = clearInterval(window[autoRefreshIntervalId]);
      }
    }
  }, [activeTabName]);

  try {
    if (!isConnectorEnabled) {
      return (
        <Alert open center variant='outlined' severity='warning' body='Missing required permission ZuoraWorkdayConnector' />
      );
    }

    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }
  
    if (!state.saved && !state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }
  
    if (state.provisionSolutionInstanceError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin.' open={true} />
      );
    }
  
    if (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === workflowName)) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
      );
    }
  
    // if (state.saved && !state.solutionInstanceUpdated) {
    //   return (
    //     <Alert center variant='outlined' severity='warning' body='Please wait while we are updating your instance' open={true} />
    //   );
    // }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support.' open={true} />
    );
  }

  return (
    <Grid container spacing={2}>
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <Modal
        id='show-job-summary-modal'
        open={openSummaryModal}
        disableBackdropClick={true}
        dsOnClose={() => setOpenSummaryModal(false)}
        header={<ModalTitle dsOnClose={() => setOpenSummaryModal(false)}>Summary Report</ModalTitle>}
        body={<div><pre>{JSON.stringify(summaryModalData, null, 2) }</pre></div>}
      />
      { <TasksModal workflowRunId={workflowRunId} workflowRunNumber={workflowRunNumber} open={openTasksModal} setOpenTasksModal={setOpenTasksModal} /> }
      { newLayout && <NextRunModal open={openNextRunModal} setOpenNextRunModal={setOpenNextRunModal} /> }
      <ExecutionModal
        open={openExecutionModal}
        setOpenExecutionModal={(open) => {
          setOpenExecutionModal(open);
          dispatch({type: tabName, payload: { 'execution_mode': '' }});
        }}
        newLayout={newLayout}
        submitButton={submitButton}
      >
        <Grid item xs={12}>
          <Card
            id='workday-execution-top-ui'
            variant={newLayout ? 'outlined' : 'elevation'}
            body={
              <Grid container>
                <Grid item xs={newLayout ? 12 : 6} sx={{ display: 'none' }}>
                  <TextField
                    fullWidth
                    select
                    label={<b>Solution Instance Name</b>}
                    value={state.settingsHash[tabName]?.['solution_instance_name'] || ''}
                  >
                    {
                      // TODO (Duc): replace these options with data from Workflow
                      (state.settingsHash['solutionInstances'] || []).map(instance => instance).map((instance: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          value={instance.solution_instance_name}
                          onClick={() => {
                            dispatch({type: tabName, payload: { 'solution_instance_name': instance.solution_instance_name }});
                          }}
                        >
                          {instance.solution_instance_name}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                <Grid item xs={newLayout ? 12 : 6}>
                  <TextField
                    fullWidth
                    select
                    label={<b>Execution Mode</b>}
                    value={state.settingsHash[tabName]?.['execution_mode'] || 'Run Now'}
                  >
                    {
                      ['Run Now', 'Schedule'].map((option: string, idx: number) => (
                        <MenuItem
                          key={idx}
                          value={option}
                          onClick={() => {
                            dispatch({type: tabName, payload: { 'execution_mode': option }});
                            const instance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === state.settingsHash[tabName]?.['solution_instance_name']);
                            if (instance) {
                              setCron({
                                ...cron,
                                timezone: instance.timezone || 'UTC',
                                expression: instance.interval ? instance.interval.split(' ') : ['*', '*', '*', '*', '*', '*']
                              });
                            }
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                {
                  state.settingsHash[tabName]?.['execution_mode'] === 'Schedule' &&
                  <Grid item xs={12}>
                    <CronBuilder
                      cron={cron}
                      setCron={setCron}
                      showInterval={false}
                    />
                  </Grid>
                }
                {
                  !newLayout &&
                  <Grid item xs={12}>
                    <Grid container direction='column' alignItems='center'>
                      <Grid item>
                        {submitButton}   
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            }
          />
        </Grid>
      </ExecutionModal>
      <Grid item xs={12}>
        <Card
          id='workday-execution-bottom-ui'
          titleBar
          header='Execution Details'
          headerAction={
            newLayout 
              ? (
                <>
                  <Button icon='play_circle_outline' tooltip='Run' dsOnClick={() => setOpenExecutionModal(true)} />
                  <Button icon='schedule' tooltip='Next Runs' dsOnClick={() => setOpenNextRunModal(true)} />
                </>
              )
              : null
          }
          body={
            <Table
              loading={loading}
              columns={columns}
              rows={rows}
              uniqueKey='workday-execution-table'
              tableActions={[
                {
                  icon: 'refresh',
                  tooltip: 'Reload Table',
                  onClick: () => getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize })
                }
              ]}
              rowActions={[
                {
                  dsGetActionData: () => ({
                    icon: 'visibility',
                    tooltip: 'Tasks',
                    onClick: onViewTasksClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'description',
                    tooltip: 'Summary',
                    // disabled: row.status !== 'Finished' || !!row.pending_tasks || !!row.stopped_tasks || !!row.failed_tasks,
                    onClick: onViewSummaryClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'stop_circle',
                    tooltip: 'Stop',
                    disabled: ['Finished', 'Stopped', 'Stopping'].includes(row.status as string),
                    onClick: onStopClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'delete_outline',
                    tooltip: 'Delete',
                    disabled: ['Queued', 'Processing', 'Pending', 'Stopping'].includes(row.status as string),
                    onClick: onDeleteClickHandler
                  })
                }
              ]}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              rowsPerPage={pageDetails.pageSize}
              rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
              dsOnPage={getExecutions}
              hideTotalRows
              singlePageLoadingOptions={singlePageLoadingOptions}
              orderable
              {...{searchable: true}}
              dsOnColumnsChange={(columns) => {
                localStorage.setItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`, JSON.stringify(columns));
              }}
              dsOnSearchChange={(args) => setSearchValue(args)}
              showNoRowsMessage={rows?.length === 0}
              searchPlaceholder='Type execution run number/id to filter results...'
            />
          }
        />
      </Grid>
    </Grid>
  );
};