import { AlertColor, Box, Checkbox, CircularProgress, Divider, FormControlLabel, ListItemButton, ListItemIcon } from '@mui/material';
import { Button, DatePicker, Grid, Modal, ModalActions, ModalTitle, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import TreeView, { Tree } from '../../../../TreeView/TreeView';
import Connect, { windmillRun } from '../../../../Connect/Connect';
import { useStoreState } from '../../../../Store';

interface ReconProps {
  openRecon: boolean;
  setOpenRecon: Dispatch<SetStateAction<boolean>>;
  tabName: string;
}

const OBJECTS_MAP = {
  '0': 'Account',
  '1': 'Contact',
  '2': 'Product',
  '3': 'UnitOfMeasure',
  '4': 'PaymentGateway',
  '5': 'PaymentTerm',
  '6': 'Invoice',
  '7': 'InvoiceItem',
  '8': 'TaxationItem',
  '9': 'PaymentMethod',
  '10': 'Payment',
  '11': 'InvoicePayment',
  '12': 'PaymentPart',
  '13': 'Subscription',
  '14': 'RatePlan',
  '15': 'RatePlanCharge',
  '16': 'SubscriptionHistory',
  '17': 'RatePlanHistory',
  '18': 'RatePlanChargeHistory',
  '19': 'RatePlanChargeTierHistory',
  '20': 'Refund',
  '21': 'RefundPart',
  '22': 'RefundInvoicePayment',
  '23': 'CreditMemo',
  '24': 'CreditMemoItem',
  '25': 'CreditMemoPart',
  '26': 'DebitMemo',
  '27': 'DebitMemoItem',
  '28': 'PaymentSchedule',
  '29': 'PaymentScheduleItem'
};

const PRODUCTS_MAP = {
  '0': 'BillingEntity',
  '1': 'Feature',
  '2': 'Product2',
  '3': 'ProductFeature',
  '4': 'ProductRatePlan',
  '5': 'ProductRatePlanCharge',
  '6': 'ProductRatePlanChargePriceSummary',
  '7': 'ProductRatePlanChargeTier',
  '8': 'ZProduct',
  '9': 'ZUnitOfMeasure'
};

const DUMMY_ACCOUNT_SELECT_ALL_TREE: Tree = {
  id: '-1',
  value: 'SelectAll',
  label: 'Select All'
};

const DUMMY_PRODUCT_CATALOG_SELECT_ALL_TREE: Tree = {
  id: '-1',
  value: 'SelectAll',
  label: 'Product Catalog'
};

// 7 days in millis
const WINDOW_LIMIT = 604800000

const Reconciliation: FC<ReconProps> = ({
  openRecon,
  setOpenRecon,
  tabName
}: ReconProps) => {
  const state = useStoreState();
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [reconStartDate, setReconStartDate] = useState(null);
  const [reconEndDate, setReconEndDate] = useState(null);
  const [resyncEnabled, setResyncEnabled] = useState(null);
  const [reconInProgress, setReconInProgress] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  const connect: Connect = (window as any).connect;

  const syncSettings = state.settingsHash[tabName] || {};

  const OBJECTS: Tree = useMemo(() => {
    return 'Account' in syncSettings ? {
      id: '-1',
      value: 'SelectAll',
      label: 'Select All',
      enabled: true,
      children: [
        {
          id: '0',
          value: 'Account',
          label: 'Account',
          enabled: !!syncSettings['Account'],
          children: [
            {
              id: '1',
              value: 'Contact',
              label: 'Contact',
              enabled: !!syncSettings['Contact']
            },
            {
              id: '2',
              value: 'Product',
              label: 'Product',
              enabled: !!syncSettings['Product']
            },
            {
              id: '3',
              value: 'UnitOfMeasure',
              label: 'Unit Of Measure',
              enabled: !!syncSettings['UnitOfMeasure']
            },
            {
              id: '4',
              value: 'PaymentGateway',
              label: 'Payment Gateway',
              enabled: !!syncSettings['PaymentGateway']
            },
            {
              id: '5',
              value: 'PaymentTerm',
              label: 'Payment Term',
              enabled: !!syncSettings['PaymentTerm']
            },
            {
              id: '6',
              value: 'Invoice',
              label: 'Invoice',
              enabled: !!syncSettings['Invoice'],
              children: [
                {
                  id: '7',
                  value: 'InvoiceItem',
                  label: 'Invoice Item',
                  enabled: !!syncSettings['InvoiceItem'],
                  children: [
                    {
                      id: '8',
                      value: 'TaxationItem',
                      label: 'Taxation Item',
                      enabled: !!syncSettings['TaxationItem']
                    }
                  ]
                }
              ]
            },
            {
              id: '9',
              value: 'PaymentMethod',
              label: 'Payment Method',
              enabled: !!syncSettings['PaymentMethod'],
              children: [
                {
                  id: '10',
                  value: 'Payment',
                  label: 'Payment',
                  enabled: !!syncSettings['Payment'],
                  children: [
                    {
                      id: '11',
                      value: 'InvoicePayment',
                      label: 'Invoice Payment',
                      enabled: !!syncSettings['InvoicePayment']
                    },
                    {
                      id: '12',
                      value: 'PaymentPart',
                      label: 'Payment Part',
                      enabled: !!syncSettings['PaymentPart']
                    }
                  ]
                }
              ]
            },
            {
              id: '13',
              value: 'Subscription',
              label: 'Subscription',
              enabled: !!syncSettings['Subscription'],
              children: [
                {
                  id: '14',
                  value: 'RatePlan',
                  label: 'Rate Plan',
                  enabled: !!syncSettings['RatePlan'],
                  children: [
                    {
                      id: '15',
                      value: 'RatePlanCharge',
                      label: 'Rate Plan Charge',
                      enabled: !!syncSettings['RatePlanCharge']
                    }
                  ]
                }
              ]
            },
            {
              id: '16',
              value: 'SubscriptionHistory',
              label: 'Subscription History',
              enabled: !!syncSettings['SubscriptionHistory'],
              children: [
                {
                  id: '17',
                  value: 'RatePlanHistory',
                  label: 'Rate Plan History',
                  enabled: !!syncSettings['RatePlanHistory'],
                  children: [
                    {
                      id: '18',
                      value: 'RatePlanChargeHistory',
                      label: 'Rate Plan Charge History',
                      enabled: !!syncSettings['RatePlanChargeHistory'],
                      children: [
                        {
                          id: '19',
                          value: 'RatePlanChargeTierHistory',
                          label: 'Rate Plan Charge Tier History',
                          enabled: !!syncSettings['RatePlanChargeTierHistory']
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: '20',
              value: 'Refund',
              label: 'Refund',
              enabled: !!syncSettings['Refund'],
              children: [
                {
                  id: '21',
                  value: 'RefundPart',
                  label: 'Refund Part',
                  enabled: !!syncSettings['RefundPart']
                },
                {
                  id: '22',
                  value: 'RefundInvoicePayment',
                  label: 'Refund Invoice Payment',
                  enabled: !!syncSettings['RefundInvoicePayment']
                }
              ]
            },
            {
              id: '23',
              value: 'CreditMemo',
              label: 'Credit Memo',
              enabled: !!syncSettings['CreditMemo'],
              children: [
                {
                  id: '24',
                  value: 'CreditMemoItem',
                  label: 'Credit Memo Item',
                  enabled: !!syncSettings['CreditMemoItem']
                },
                {
                  id: '25',
                  value: 'CreditMemoPart',
                  label: 'Credit Memo Part',
                  enabled: !!syncSettings['CreditMemoPart']
                }
              ]
            },
            {
              id: '26',
              value: 'DebitMemo',
              label: 'Debit Memo',
              enabled: !!syncSettings['DebitMemo'],
              children: [
                {
                  id: '27',
                  value: 'DebitMemoItem',
                  label: 'Debit Memo Item',
                  enabled: !!syncSettings['DebitMemoItem']
                }
              ]
            },
            {
              id: '28',
              value: 'PaymentSchedule',
              label: 'Payment Schedule',
              enabled: !!syncSettings['PaymentSchedule'],
              children: [
                {
                  id: '29',
                  value: 'PaymentScheduleItem',
                  label: 'Payment Schedule Item',
                  enabled: !!syncSettings['PaymentScheduleItem']
                }
              ]
            }
          ]
        }
      ]
    } : DUMMY_ACCOUNT_SELECT_ALL_TREE;
  }, [state.settingsHash[tabName]]);

  const PRODUCTS: Tree = useMemo(() => {
    return 'ProductCatalog' in syncSettings ? {
      id: '-1',
      value: 'SelectAll',
      label: 'Product Catalog',
      enabled: true,
      children: [
        {
          id: '0',
          value: 'BillingEntity',
          label: 'Billing Entity',
          enabled: !!syncSettings['BillingEntity']
        },
        {
          id: '1',
          value: 'Feature',
          label: 'Feature',
          enabled: !!syncSettings['Feature']
        },
        {
          id: '2',
          value: 'Product2',
          label: 'Product2',
          enabled: !!syncSettings['Product2']
        },
        {
          id: '3',
          value: 'ProductFeature',
          label: 'Product Feature',
          enabled: !!syncSettings['ProductFeature']
        },
        {
          id: '4',
          value: 'ProductRatePlan',
          label: 'Product Rate Plan',
          enabled: !!syncSettings['ProductRatePlan']
        },
        {
          id: '5',
          value: 'ProductRatePlanCharge',
          label: 'Product Rate Plan Charge',
          enabled: !!syncSettings['ProductRatePlanCharge']
        },
        {
          id: '6',
          value: 'ProductRatePlanChargePriceSummary',
          label: 'Product Rate Plan Charge Price Summary',
          enabled: !!syncSettings['ProductRatePlanChargePriceSummary']
        },
        {
          id: '7',
          value: 'ProductRatePlanChargeTier',
          label: 'Product Rate Plan Charge Tier',
          enabled: !!syncSettings['ProductRatePlanChargeTier']
        },
        {
          id: '8',
          value: 'ZProduct',
          label: 'ZProduct',
          enabled: !!syncSettings['ZProduct']
        },
        {
          id: '9',
          value: 'ZUnitOfMeasure',
          label: 'ZUnit Of Measure',
          enabled: !!syncSettings['ZUnitOfMeasure']
        }
      ]
    } : DUMMY_PRODUCT_CATALOG_SELECT_ALL_TREE;
  }, [state.settingsHash[tabName]]);

  function parseEnvironment() : string {
    let host = window.location.host;
    if (host.includes('staging2.zuora.com')) {
      return 'STG';
    } else if(host.includes('apisandbox.zuora.com')) {
      return 'SBX01'
    } else if(host.includes('sandbox.eu.zuora.com')) {
      return 'SBX00'
    } else if(host.includes('sandbox.na.zuora.com')) {
      return 'SBX08'
    } else if(host.includes('test.zuora.com')) {
      return 'US_CSBX'
    } else if(host.includes('test.eu.zuora.com')) {
      return 'EU_CSBX'
    } else if(host.includes('test.ap.zuora.com')) {
      return 'AP_CSBX'
    } else if(host.includes('eu.zuora.com')) {
      return 'PROD00'
    } else if(host.includes('na.zuora.com')) {
      return 'PROD08'
    } else if(host.includes('zuora.com')) {
      return 'PROD02'
    } else {
      return 'UNKNOWN'
    }
  }

  return (
    <>
      { toast.show && <ToastController severity={toast.severity} message={toast.msg} /> }
      <Modal
        id='recon-modal'
        open={openRecon}
        dsOnClose={() => setOpenRecon(false)}
        body={
          <Grid container direction='column'>
            <Grid item>
              <Typography>Reconciliation Job Configuration</Typography>
            </Grid>
            <Divider style={{ marginTop: '20px', marginLeft: '10px' }} />
            <Grid item>
              <Grid container direction='row' alignContent='flex-start'>
                <Grid item xs='auto'>
                  <DatePicker label='Reconcile Start Date' value={reconStartDate} dsOnChange={(value) => setReconStartDate(value)} required />
                </Grid>
                <Grid item xs='auto'>
                  <DatePicker label='Reconcile End Date' value={reconEndDate} dsOnChange={(value) => setReconEndDate(value)} required />
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '20px', marginLeft: '10px' }} />
              <Grid item xs='auto'>
                  <FormControlLabel
                    label = 'Auto Resync Results'
                    control = {<Checkbox onChange={(value) => setResyncEnabled(value)}/>}
                  />
              </Grid>
            <Divider style={{ marginTop: '20px', marginLeft: '10px' }} />
            <Grid item>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <TreeView name='Account and Related Objects' root={OBJECTS} setSelectedObjects={setSelectedObjects} defaultExpanded={['-1', '0']} />
                </Grid>
                <Grid item xs={6}>
                  <TreeView name='Product Catalog' root={PRODUCTS} setSelectedObjects={setSelectedProducts} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        header={
          <ModalTitle dsOnClose={() => setOpenRecon(false)}>Reconciliation</ModalTitle>
        }
        footer={
          <ModalActions>
            <Button variant='outlined' dsOnClick={() => setOpenRecon(false)}>Cancel</Button>
            <Box sx={{ m:1, position: 'relative' }}>
              <Button
                disabled={reconInProgress}
                dsOnClick={async () => {
                  Connect.log(reconStartDate)

                  if (reconStartDate == null) {
                    alert('Reconcile Start Date is required!!!');
                    return;
                  }

                  if (reconEndDate == null) {
                    alert('Reconcile End Date is required!!!');
                    return;
                  }

                  if (new Date(Date.parse(reconEndDate)).getTime() - new Date(Date.parse(reconStartDate)).getTime() > WINDOW_LIMIT) {
                    alert('Can only reconcile a maximum of 7 days data!!!')
                    return;
                  }

                  if (selectedObjects.length === 0 && selectedProducts.length === 0) {
                    alert('Select an object to reconcile!!!');
                    return;
                  }

                  try {
                    setReconInProgress(true);
                    setToast({ ...toast, show: false });
                    
                    const objectTypes = [];

                    if (selectedObjects && selectedObjects.length > 0) {
                      selectedObjects.forEach((obj) => {
                        objectTypes.push(OBJECTS_MAP[obj]);
                      });
                    }

                    if (selectedProducts && selectedProducts.length > 0) {
                      selectedProducts.forEach((obj) => {
                        objectTypes.push(PRODUCTS_MAP[obj]);
                      });
                    }

                    const cookies: any[] = (connect.current_user as any).cookies || [];

                    let zuoraTenantIdFromCookie: string;

                    cookies.forEach((cookie) => {
                      if (cookie[0] === 'Zuora-Tenant-Id') {
                        zuoraTenantIdFromCookie = cookie[1];
                      }
                    });

                    let windmillParams = {
                      script_path: 'f/daco/recon_flow',
                      params: {
                        'TENANT_ID': zuoraTenantIdFromCookie,
                        'RECON_FROM_DATE': reconStartDate.toString().slice(0, 23) + 'Z',
                        'RECON_TO_DATE': reconStartDate.toString().slice(0, 23) + 'Z',
                        'ENVIRONMENT': parseEnvironment(),
                        'OBJECT_TYPE': objectTypes.join(','),
                        'RESYNC_ENABLED': resyncEnabled != null && resyncEnabled.target.checked,
                      },
                      async: true,
                    }

                    const response = await windmillRun(windmillParams);

                    if (response.status === 400) {
                      setReconInProgress(false);
                      setToast({ ...toast, show: true, msg: 'Failed to submit a sync request due to a malformed payload.', severity: 'error' });
                      return;
                    }

                    setReconInProgress(false);
                    setToast({ ...toast, show: true, msg: 'Successfully submitted a reconcile request.', severity: 'success' });
                  } catch (error) {
                    Connect.log(error);
                    setReconInProgress(false);
                    setToast({ ...toast, show: true, msg: 'Failed to submit a reconcile request. Please try again later!', severity: 'error' });
                  }
                }}
              >
                Run
              </Button>
              {
                reconInProgress &&
                <CircularProgress
                  size={24}
                  sx={{
                    color: designTokens.colors.blue500,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              }
            </Box>
          </ModalActions>
        }
        disableBackdropClick
        fullWidth
      />
    </>
  );
};

export default Reconciliation;
